export default {
  methods: {
    $_loaderStart() {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
        appLoading.style.zIndex = -1
      }
    },
    $_loaderStop() {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block'
        appLoading.style.zIndex = 11
      }
    },
  },
}
